import React from 'react'
import "./Contact.scss"
import { useTranslation } from 'react-i18next'
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { SiGmail } from "react-icons/si";
import { IoLogoWhatsapp } from "react-icons/io";




export const Contact = () => {
  const [t] = useTranslation();
  return (
    <>
      <div className="about" id='CONTACT'>
        <h2>    {
          t('CONTACT ME')
        }</h2>

        <div className="contact">
        </div>

        <footer>
            <div class="social-media">
              <a href="https://github.com/julianclavijo2" target="_blank" rel="noopener noreferrer">
                <FaGithub fill='white' size={'25px'} />
              </a>
              <a href="https://www.linkedin.com/in/julian-clavijo/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin fill='white' size={'25px'} />
              </a>
              <a href="mailto:julianclavijo2@gmail.com">
                <SiGmail fill='white' size={'25px'} />
              </a>
              <a href="https://wa.me/+3194168176" target="_blank" rel="noopener noreferrer">
                <IoLogoWhatsapp fill='white' size={'25px'} />
              </a>
            
            </div>
            
            <p>  {
          t('ABOUT')
        }</p>

            <p>©  {
          t('RESERVED')
        }</p>
          </footer>

      </div>

    </>
  )
}
