import React  from 'react'
import "./Projects.scss"
import { useTranslation } from 'react-i18next'
import market from "../../assets/images/market.jpg"
import portafol from "../../assets/images/porfatol.jpg"
import { IoLogoAngular } from "react-icons/io";
import { FaHtml5 } from "react-icons/fa";
import { SiTypescript } from "react-icons/si";
import { FaCss3Alt } from "react-icons/fa";
import { RiReactjsFill } from "react-icons/ri";
import { FaSass } from "react-icons/fa";


const projects = [
  {
    id: 0,
    name: "Market",
    link: "https://julianclavijo2.github.io/market/",
    img: market,
    stack: [

      {
        id: 0,
        icon: <IoLogoAngular fill='white' />,
        title: "Angular"
      },
      {
        id: 1,
        icon: <FaHtml5 fill='white'  />,
        title: "HTML5"
      },
      {
        id: 2,
        icon: <SiTypescript fill='white' />,
        title: "TypeScript"
      },
      {
        id: 3,
        icon: <FaCss3Alt fill='white' />,
        title: "CSS3"
      },


    ]
  },
  {
    id: 1,
    name: "Portafolio",
    link: "https://www.juliancclavijo.com/",
    img: portafol,
    stack: [

      {
        id: 0,
        icon: <RiReactjsFill fill='white' />,
        title: "React"
      },
      {
        id: 1,
        icon: <FaHtml5 fill='white' />,
        title: "HTML5"
      },
      {
        id: 2,
        icon: <SiTypescript fill='white' />,
        title: "TypeScript"
      },
      {
        id: 3,
        icon: <FaSass fill='white' />,
        title: "Sass"
      },


    ]
  }
]

export const Projects = () => {
  const [t] = useTranslation();
  
  return (
    <>
      <div className="projects" id='PROJECTS' >
        <h1>  {
          t(`PROJECTS`)
        }</h1>
        <div className="gridContainer">

          {projects.map((item) => (
            <a href={item.link} key={item.id}>
              <div className="items" >
                <img src={item.img} alt="nothing"  />
                <div className="layer" >

                  <div className="description">
                    {item.stack.map((stackItem) => (
                      <div className="stack" key={stackItem.title}>
                       {stackItem.icon}
                        <p>{stackItem.title}</p>
                      </div>

                    ))}


                    {/*    <div className="stack">
                        <IoLogoAngular fill='white' />
                        <p>Angular</p>
                      </div>
                      <div className="stack">
                        <FaHtml5 fill='white' />
                        <p>HTML5</p>
                      </div>
                      <div className="stack">
                        <FaCss3Alt fill='white' />
                        <p>Css3</p>
                      </div> */}
                  </div>


                </div>
              </div>

            </a>


          ))}

        </div>
      </div >

    </>
  )
}
