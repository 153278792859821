import React from 'react'
import { useTranslation } from 'react-i18next'
import  "./Experience.scss";
import book from "../../assets/images/bookorooms.png"


const companies = [
    {
        id: 0,
        nameCompany: 'NEXOS',
        img: "https://nxs.com.co/wp-content/uploads/2023/09/Logos-nexos_Mesa-de-trabajo-1.png",
        desc: "NEXOS",
        date:"DATEONE",
        stack:"STACKONE"
    },
    {
        id: 1,
        nameCompany: 'CHEIL',
        img: "https://media.licdn.com/dms/image/D4E0BAQEDr5jfasWNkQ/company-logo_200_200/0/1705368493254?e=1727913600&v=beta&t=_IhPEkbL8YVQE-AJ4UpGCv8tGbdNSgh7TRqRjLjxj14",
        desc: "CHEIL",
        date:"DATETWO",
        stack:"STACKTWO"
    },
    {
        id: 2,
        nameCompany: 'BOOKOROOMS',
        img: book,
        desc: "BOOKOROOMS",
         date:"DATETHREE",
        stack:"STACKTHREE"
    }

]

export const Experience = () => {
    const [t] = useTranslation();
    return (
        <>
        <div className="experience" id='EXPERIENCE'>

           <h2>{t('COLLABORATIONS')}</h2> 
           
            <div className="gridExp">
           
                {companies.map((item) => (
                    <div className="company">
                        <div className="title">
                            <img  style={item.id === 1 ? { width: '60px'} : {width:'110px'}} src={item.img} alt="" />
                            
                            <div style={item.id === 1 ? {marginLeft: '70px'} : {marginLeft:'22px'}} className="info">
                            <h4>{item.nameCompany}


                                
                            </h4>
                            <small>{t(item.date)}</small>
                            <p>{t(item.stack)}</p>
                            </div>
                        
                        </div>
                    {/*      <p>
                            {
                                t(item.desc)
                            }
                        </p>  */}
                    </div>
                ))}



            </div>
            </div>
        </>
    )
}
