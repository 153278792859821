
import React from 'react';
import { LiaLanguageSolid } from "react-icons/lia";
import { Menu } from './../Menu/Menu';
import './../Header/Header.scss'
import { BiMenuAltLeft } from "react-icons/bi";
import { allContext } from "../Context/Context";
import { MdOutlineClose } from "react-icons/md";
import { useTranslation } from 'react-i18next';




export const Header = () => {
  const { i18n } = useTranslation();
  console.log("header")
  const itemsMenu = [

    { key: "home", name: "HOME" },
    { key: "projects", name: "PROJECTS" },
    { key: "experience", name: "EXPERIENCE" },
    { key: "Contact", name: "CONTACT" },


  ]

  const { openmenu, setOpen } = React.useContext(allContext);
  return (

    <>
      <div className={openmenu ? 'row firstIndex' : 'row'}>
        <LiaLanguageSolid fill='white' size={'40px'} className='language' onClick={() => {
          i18n.language === "en" ?
            i18n.changeLanguage("es") :
            i18n.changeLanguage("en")





        }} />
        {
          !openmenu
            ?
            <BiMenuAltLeft fill='white' size={'40px'} className='menu' onClick={() => {
              setOpen(!openmenu)
            }} />
            :
            <MdOutlineClose size={'40px'} fill='white' className='close' onClick={() => {
              setOpen(!openmenu)
            }} />
        }





        <ul className={openmenu ? 'openMenu' : ''}>
          {itemsMenu.map(item => (

            <Menu
              key={item.key}
              title={item.name}
            />

          ))}

        </ul>
      </div>

    </>
  )
}
