import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { SiGmail } from "react-icons/si";
import './Body.scss'
import { allContext } from '../Context/Context';
import hand from "../../assets/images/wave.png"
import laptop from "../../assets/images/laptop-screen.png"
import fire from "../../assets/images/fire.png"
import heart from "../../assets/images/heart.png"
import code from "../../assets/images/coding.png"


export const Body = () => {
  const [t] = useTranslation();
  const { openmenu } = React.useContext(allContext);
  return (
    <>

      <div className={openmenu ? 'body zindex' : 'body'}>
        <div className="body-text">
          <h1>
            {
              t(`HOLA`)
            }
            <img src={hand} alt="" />
            {
              t(`TITLE`)
            }
               <img src={laptop} alt="" />
          </h1>
          <h2>{
            t('SUBTITLE')
          }</h2>
          <small>
          <img src={fire} alt="" />
            {
            t('MAINTEXT')
          }
          
          <img src={heart} alt="" />
          {
            t('MAINTEXT2')
          }
           <img src={code} alt="" />
          </small>
         
          {/*     <p>{t('CONTACT')}</p>

          <div className="media">
            <a href='https://github.com/julianclavijo2' target="_blank" rel="noopener noreferrer">
              <FaGithub fill='white' size={'25px'} />
            </a>
            <a href='https://www.linkedin.com/in/julian-clavijo/' target="_blank" rel="noopener noreferrer">
              <FaLinkedin fill='white' size={'25px'} />
            </a>

            <a href='mailto:julianclavijo2@gmail.com'>
              <SiGmail fill='white' size={'25px'} />
              </a>
          </div> */}
        </div>
        <div className="body-image">
          <div className="image" >
          </div>

        </div>

      </div>

    </>
  )
}
