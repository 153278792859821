import React from 'react'
import './Menu.scss'
import { useTranslation } from 'react-i18next'
import { Link } from "react-scroll";

export const Menu = (props) => {
  const [t] = useTranslation();

  return (
    <>
     <Link to={props.title} smooth={true} offset={50} duration={1000} >
      <li>
       
        {
          t(`${props.title}`)
        }
    
        </li>
        </Link>

        
       
    

    </>

    
  )
}
