import React, { children } from 'react'


const allContext = React.createContext();

function Context({ children }) {



    const [openmenu, setOpen] = React.useState('');


    return (

        <allContext.Provider value={{
            openmenu,
            setOpen
        }
        }>
            {children}
        </allContext.Provider>

    );
}

export { allContext, Context }
