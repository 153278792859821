import React from 'react'
import { allContext } from "../Context/Context";
import { Header } from "../Header/Header";
import { Body } from "../Body/Body";
import {Projects} from "../projects/Projects";
import { Experience } from '../Experience/Experience';
import { Contact } from '../About/Contact';
export const Home = () => {
  console.log("home")
  const { openmenu , setOpen } = React.useContext(allContext);

  return (
    <>
    
      <Header openmenu={openmenu} setOpen={setOpen}/>  
      <Body />
      <Projects />
      <Experience/>
     <Contact />
    </>
  )
}
